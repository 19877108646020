import * as React from "react"

export type HeaderProps = {
  title: string;
  date: string;
};
export const Header: React.FC<HeaderProps> = ({ title, date }) => (
  <header className="index-post-title">
    <h1 className="text-xl" itemProp="headline">
      {title}
    </h1>
    {/* TODO reenable header in blog posts but not in topics <h2 className="text-sm date">{date}</h2> */}
  </header>
);
export default Header;