import React, { ReactNode, useCallback, useState } from "react"
import { Location } from "history"
import { Link } from "gatsby"

import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"

import { Menu, ThemeSwitch } from "./common"

type SocialLinkProps = {
  to: string
  title: string
}
const SocialLink: React.FC<SocialLinkProps> = ({ title, to, children }) => (
  <li className="social-link">
    <a
      className="inline-block no-underline hover:underline py-2 px-4"
      href={to}
      title={title}
    >
      {children}{title}
    </a>
  </li>
)

type LocationProps = {
  location: Location
}

type LayoutProps = {
  title: string
  children: ReactNode
} & LocationProps

// type TopNavMenuItemProps = {
//   href: string
//   title: string
// } & LocationProps

// const TopNavMenuItem: React.FC<TopNavMenuItemProps> = ({
//   href,
//   title,
//   location,
// }) => {
//   return location.pathname.startsWith(href) ? (
//     <li className="mr-3 py-2 lg:py-0">
//       <Link
//         className="inline-block py-2 px-4 font-bold hover:underline italic"
//         to={href}
//       >
//         {title}
//       </Link>
//     </li>
//   ) : (
//     <li className="mr-3 py-2 lg:py-0">
//       <Link
//         className="inline-block no-underline hover:underline py-2 px-4"
//         to={href}
//       >
//         {title}
//       </Link>
//     </li>
//   )
// }

type TopNavMenuProps = {} & LocationProps

const TopNavMenu: React.FC<TopNavMenuProps> = ({ location }) => {
  return (
    <ul className="list-reset lg:flex justify-end items-center">
      {/* <TopNavMenuItem location={location} title="Bodywork" href="/bodywork" />
      <TopNavMenuItem location={location} title="NVC" href="/nvc" />
      <TopNavMenuItem
        location={location}
        title="Technology"
        href="/technology"
      /> */}
     <li className="mr-3 py-2 lg:py-0">
      <ThemeSwitch />
      </li>
    </ul>
  )
}
const Layout: React.FC<LayoutProps> = ({ title, location, children }) => {
  const [topMenuOpen, setTopMenuOpen] = useState<boolean>(false)
  const toggleMenuOpen = useCallback(
    () => setTopMenuOpen(!topMenuOpen),
    [topMenuOpen, setTopMenuOpen]
  )

  return (
    <div className="tracking-wider tracking-normal">
      <nav id="header" className="w-full z-10 top-0 border-b border-gray-400">
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
          <div className="pl-4 flex items-center">
            <a
              className="no-underline hover:no-underline font-extrabold text-xl"
              href="/"
            >
              {title}
            </a>
          </div>

          <div className="block lg:hidden pr-4">
            <button
              id="nav-toggle"
              className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-blue-900 hover:border-blue-500 appearance-none focus:outline-none"
              onClick={toggleMenuOpen}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`w-full flex-grow lg:flex  lg:content-center lg:items-center lg:w-auto ${
              topMenuOpen ? `` : `hidden`
            } lg:block mt-2 lg:mt-0 z-20`}
            id="nav-content"
          >
            <div className="flex-1 w-full mx-auto max-w-sm content-center py-4 lg:py-0 hidden md:inline-block"></div>
            <TopNavMenu location={location} />
          </div>
        </div>
      </nav>
      <div className="container w-full flex flex-wrap mx-auto px-2 pt-1 xl:pt-4 mt-1 xl:mt-4">
        <Menu location={location} isOpen={topMenuOpen} />
        <div className="w-full lg:w-4/5 p-2 mt-0 md:mt-2 leading-normal">
          {children}
        </div>
      </div>
      {/*    <!-- footer with social links--> */}
      <hr />
      <footer>
        <div className="container mx-auto flex py-8">
          <div className="w-full mx-auto flex flex-wrap">
            <div className="flex w-full lg:w-1/2 ">
              <div className="px-8 text-xl">
                Wil Johnson
              </div>
            </div>
            <div className="flex w-full lg:w-1/2 lg:justify-end lg:text-right">
              <div className="px-8">
                <ul className="list-reset lg:flex justify-end items-center">
                  <SocialLink
                    to="https://www.linkedin.com/in/wilj/"
                    title="LinkedIn"
                  >
                    <FaLinkedin />
                  </SocialLink>
                  <SocialLink to="https://github.com/wilj" title="Github">
                    <FaGithub />
                  </SocialLink>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
