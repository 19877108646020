import { IEdge } from "@/definitions"
import { graphql, Link, StaticQuery } from "gatsby"
import { Location } from "history"
import * as React from "react"
import { useMemo } from "react"

type PostMap = Map<string, PostMap> & { edge?: IEdge }

const postsToTree = (posts: IEdge[]) => {
  const root: PostMap = new Map()

  posts.forEach(post => {
    const slug = post.node.fields.slug
    const parts = slug.split("/").filter(x => x)

    // make a path in the map to it
    let current = root
    for (let i = 0; i < parts.length; i++) {
      const key = parts[i]
      let tmp = current.get(key)
      if (tmp === undefined) {
        tmp = new Map()
        current.set(key, tmp)
      }
      current = tmp
      if (i === parts.length - 1) {
        current.edge = post
      }
    }
  })
  return root
}

// TODO extract the logic for title = slug || title

const PostTree: React.FC<{ map: PostMap; location: Location; depth?: number }> =
  ({ map, depth, location }) => {
    const depthEm = depth ? `${depth}em` : `0`
    const keys = Array.from(map.keys())
    const sorted = useMemo(
      () =>
        keys.sort((k1, k2) => {
          const v1 = map.get(k1)?.edge?.node!
          const v2 = map.get(k2)?.edge?.node!
          const title1 = v1.frontmatter.title || v1.fields?.slug
          const title2 = v2.frontmatter.title || v2.fields?.slug
          return title1.localeCompare(title2, undefined, {
            sensitivity: "accent",
          })
        }),
      [keys]
    )
    return (
      <ul className="list-reset" style={{ paddingLeft: depthEm }}>
        {sorted.map(key => {
          const val = map.get(key)
          const post = val?.edge?.node!
          const slug = post.fields.slug
          const title = post.frontmatter.title || slug
          const pathname = `${location.pathname}/`

          const linkClasses = pathname.startsWith(slug) ?
            "block pl-2 py-1 my-1 align-middle hover:text-blue-500 border-l-4 border-blue-500 hover:border-blue-500" :
            "block pl-2 py-1 my-1 align-middle hover:text-blue-500 border-l-4 border-transparent hover:border-gray-400";
          const spanClasses = pathname.startsWith(slug) ? "font-bold" : "border-b border-gray-400"

          const liClasses = "lg:hover:bg-transparent"
          return (
            <React.Fragment key={key}>
              <li
                className={liClasses}
              >
                <Link
                  to={slug}
                  className={linkClasses}
                >
                  <span className={spanClasses}>{title}</span>
                </Link>
              </li>
              {val && (
                <li
                  className={liClasses}
                >
                  <PostTree
                    map={val}
                    location={location}
                    depth={depth ? depth + 1 : 1}
                  />
                </li>
              )}
            </React.Fragment>

          )
        })}
      </ul>
    )

  }

export type MenuProps = {
  location: Location
  isOpen: boolean
}
const menuQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`

export const Menu: React.FC<MenuProps> = ({ location, isOpen }) => {
  return (
    <StaticQuery
      query={menuQuery}
      render={data => {
        const posts = data.allMdx.edges
        const tree = useMemo(() => postsToTree(posts), [posts])

        return (
          <div className="w-full lg:w-1/5 text-lg leading-normal">
            <div
              className={`w-full ${isOpen ? `` : `hidden`} overflow-x-hidden overflow-y-auto lg:block mt-0 `}
              id="menu-content"
            >
              <PostTree location={location} map={tree} />
            </div>
          </div>
        )
      }}
    />
  )
}
export default Menu
